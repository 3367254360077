import { Box, Button, Dialog, DialogContent, DialogTitle, IconButton } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AnyAction } from 'redux';
import { ThunkDispatch } from '@reduxjs/toolkit';
import PrintIcon from '@mui/icons-material/Print';
import { QRCodeCanvas } from 'qrcode.react';
import { RootState } from '../../store/store';
import { toggleDialogInfo } from '../../components/dialog/dialogInfo.slice';
import { DialogDelete } from '../../components/DialogDelete';
import { StudioDialogAddEdit } from './components/StudioDialogAddEdit';
import {
  closeStudioDialogDelete,
  fetchStudios,
  openStudioDialogAddEdit,
  setQrCodeForPrint,
  setShowQrCode
} from './studiosPage.slice';
import StudiosService from '../../services/studios/studios.service';
import { Studio } from '../../services/studios/studios.types';
import CloseIcon from '@mui/icons-material/Close';
import { useReactToPrint } from 'react-to-print';
import { fetchTimezones } from '../../store/dictionaries.slice';
import GridBoolean from '../../utils/renderers/GridBoolean';
import { DateTime } from 'luxon';
import AddCircleIcon from '@mui/icons-material/AddCircle';

const StudiosPage = () => {
  const dispatch: ThunkDispatch<Studio[], any, AnyAction> = useDispatch();
  const componentRef = useRef(null);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  useEffect(() => {
    dispatch(fetchTimezones());
  }, [dispatch]);

  const {
    data,
    showQrCode,
    qrCodeForPrint,
    dialogs: { delete: { deletingId, open: dialogDeleteOpened } },
  } = useSelector((state: RootState) => state.studios);

  const handleConfirmDelete = async () => {
    if (deletingId !== null) {
      try {
        await StudiosService.delete(deletingId);
        dispatch(fetchStudios());
        dispatch(closeStudioDialogDelete({}));
        dispatch(toggleDialogInfo({
          open: true,
          type: 'info',
          message: 'Room successfully deleted',
        }));
        setTimeout(() => dispatch(toggleDialogInfo({ open: false })), 3000);
      } catch (error) {
        dispatch(toggleDialogInfo({
          open: true,
          type: 'error',
          message: 'Error while delete studio: ' + error,
        }));
      }
    } else {
      console.error('Deleting id is not found');
    }
  };

  const handlePrintQrCodeButtonClick = (code: string) => {
    dispatch(setQrCodeForPrint(code));
    dispatch(setShowQrCode(true));
  };

  const columns: GridColDef[] = [{
    field: 'id', // hidden
  },{
    field: 'photo',
    headerName: 'Photo',
    width: 80,
    renderCell: (params) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <img src={ params.row.photo || '/logo96.png' } width={40} />
        </div>
    ),
  },{
    field: 'name',
    headerName: 'Name',
    width: 200
  },{
    field: 'description',
    headerName: 'Description',
    sortable: false,
    flex: 1,
  },{
    field: 'code',
    headerName: 'Code',
    sortable: false,
    flex: 1,
    renderCell: (params) => (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {params.row.code}
        <IconButton onClick={() => handlePrintQrCodeButtonClick(params.row.code)}>
          <PrintIcon />
        </IconButton>
      </div>
    ),
  },{
    field: 'timezone',
    headerName: 'Timezone',
    sortable: false,
    flex: 1,
  }/*,{
    field: 'rules',
    headerName: 'Rules',
    sortable: false,
    flex: 1,
  }*/,{
    field: 'enabled',
    headerName: 'Enabled',
    sortable: false,
    renderCell: GridBoolean,
    flex: 1,
  },{
    field: 'workTimeEnd',
    headerName: 'Work time',
    sortable: false,
    renderCell: (params) => {
      const [start, end]: [DateTime, DateTime] = [DateTime.fromISO(params.row.workTimeStart), DateTime.fromISO(params.row.workTimeEnd)];
      return `${start.toFormat('HH:mm')} - ${end.toFormat('HH:mm')}`;
    },
    flex: 1,
  },{
    field: 'actions',
    headerName: 'Actions',
    width: 100,
    sortable: false,
    renderCell: (params) => (
      <>
        <IconButton onClick={ () => dispatch(openStudioDialogAddEdit({
          mode: 'edit',
          ...params.row,
        })) }>
          <EditIcon />
        </IconButton>
        <IconButton onClick={() => {
          dispatch(toggleDialogInfo({
            open: true,
            type: 'info',
            message: `Deleting/blocking studio is under construction. Please contact your manager.`,
          }));
        }}>
          <DeleteIcon />
        </IconButton>
      </>
    ),
  }];

  useEffect(() => {
    dispatch(fetchStudios());
  }, [dispatch]);

  return (
    <Box>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
        <Button
          variant="contained"
          sx={{ marginBottom: 1 }}
          onClick={ () => dispatch(openStudioDialogAddEdit({ mode: 'add' })) }
          startIcon={<AddCircleIcon />}
        >Add studio</Button>
      </Box>

      <DialogDelete
        open={dialogDeleteOpened}
        question="Are you sure you want to delete this studio?"
        onClose={() => dispatch(closeStudioDialogDelete({}))}
        onSubmit={handleConfirmDelete}
      />

      <StudioDialogAddEdit />

      <Dialog open={showQrCode}>
        <DialogTitle></DialogTitle>
        <IconButton
            aria-label="close"
            onClick={ () => dispatch(setShowQrCode(false))}
            sx={{ position: 'absolute', right: 8, top: 8 }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <Box ref={componentRef}>
            Follow us using this code
            <br/><br/>
            <QRCodeCanvas value={qrCodeForPrint} />
          </Box>
          <br/>
          <button onClick={handlePrint}>Print code!</button>
        </DialogContent>
      </Dialog>

      <DataGrid
        sx={{ '&, [class^=MuiDataGrid-root]': { border: 'none' } }}
        initialState={{
          columns: {
            columnVisibilityModel: {
              id: false,
            }
          }
        }}
        columns={columns}
        hideFooter={true}
        rows={data}
        disableRowSelectionOnClick
      />
    </Box>
  );
};

export default StudiosPage;
